.hoverable {
  transition: background-color 0.3s ease;
}

.hoverable:hover {
  background-color: #f0f0f0;
  color: #000;
}

/* Multi-Select Used for Country Selection*/

.multi-select .select__control {
  background-color: var(--bs-body-bg);
  border: 1px solid var(--border-color-light); 
  color: #fff !important;
}

/* For focused state */
.multi-select .select__control--is-focused {
  border-color: #555 !important;
  box-shadow: 0 0 0 1px #555 !important;
}

/* Dropdown menu */
.multi-select .select__menu {
  background-color: #333 !important;
  color: #fff !important;
}

/* Dropdown options */
.multi-select .select__option {
  padding: 10px !important;
  background-color: transparent !important;
  color: #ddd !important;
}

.multi-select .select__option--is-focused {
  background-color: #444 !important;
  color: #fff !important;
}

.multi-select .select__option--is-selected {
  background-color: #555 !important;
  color: #fff !important;
}

/* Multi-value (selected tags) */
.multi-select .select__multi-value {
  background-color: #444 !important;
  color: #fff !important;
}

.multi-select .select__multi-value__label {
  color: #fff !important;
}

.multi-select .select__multi-value__remove {
  color: #fff !important;
}

.multi-select .select__multi-value__remove:hover {
  background-color: #666 !important;
  color: #fff !important;
}

/* Placeholder styling */
.multi-select .select__placeholder {
  color: #999 !important;
}