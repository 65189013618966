/* Basic card styling */
.product-card {
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 10px 2px #000000;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  --bs-card-bg: #000;
  text-align: left; 
}

/* Smooth zoom effect on hover */
.product-card:hover {
  transform: scale(1.03);
}

/* Product image styling */
.product-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Card body styling */
.product-card-body {
  padding: 16px;
  background: linear-gradient(to top, #4f013d, rgba(54, 0, 44, 0.8));
  color: white;
}

/* Product name styling */
.product-card .card-title {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  color: #ffffff;
}

/* Product description styling */
.product-card .card-text {
  font-size: 0.9rem;
  margin: 8px 0;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
  color: #d1d1d1;
  text-align: left;
  max-height: 8em;
  line-height: 1.4em;
}

/* Price styling */
.product-card .product-price {
  font-size: 1rem;
  font-weight: bold;
  color: #b8e986;
}

/* Button styling (optional) */
.product-buttons a {
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 10px 0;
  text-align: center;
  color: white;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.product-buttons a:hover {
  background-color: #0056b3;
}