.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --text-color: #e2e2e2;
  --bg-color: #2e2f31;
  --bg-color-secondary: #161b22;
  --bg-color-tertiary: #21262c;
  --border-color: #30363d;
  --box-shadow: #282a32;
  --card-shadow: rgba(0, 0, 0, .3);
  --black: #fff;
  --dark-grey: #999;
  --light-grey: #666;
  --white: #000;
  --grey3: #bbb;
  --grey12: #333;
  --grey14: #111;
  --bg-color-light: #161b22;
  --bg-color-back: #2a112a;
  --bg-color-float: #161b22;
  --bg-color-blur: rgba(37, 39, 42, .9);
  --bg-color-float-blur: rgba(22, 27, 34, .9);
  --text-color-light: #e5e5e5;
  --text-color-lighter: #e8e8e8;
  --text-color-bright: #eeeeee;
  --border-color-light: #2e333a;
  --border-color-dark: #394048;
  --theme-color: #3eaf7c;
  --navbar-height: 3.75rem;
  --navbar-horizontal-padding: 1.5rem;
  --navbar-vertical-padding: 0.7rem;
  --navbar-mobile-height: 3.25rem;
  --navbar-mobile-horizontal-padding: 1rem;
  --navbar-mobile-vertical-padding: .5rem;
  --navbar-bg-color: var(--bg-color-blur);
  --sidebar-bg-color: var(--bg-color-blur);
  --sidebar-width: 18rem;
  --sidebar-mobile-width: 16rem;
  --content-width: 780px;
  --home-page-width: 1160px;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", STHeiti, "Microsoft YaHei", SimSun, sans-serif;
  --font-family-heading: Georgia Pro, Crimson, Georgia, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", STHeiti, "Microsoft YaHei", SimSun, sans-serif;
  --font-family-mono: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  --line-numbers-width: 2.5rem;
  --color-transition: .3s ease;
  --transform-transition: .3s ease;
  --vp-bg: var(--bg-color);
  --vp-bgl: var(--bg-color-light);
  --vp-bglt: var(--bg-color-tertiary);
  --vp-c: var(--text-color);
  --vp-cl: var(--text-color-light);
  --vp-clt: var(--text-color-lighter);
  --vp-brc: var(--border-color);
  --vp-brcd: var(--border-color-dark);
  --vp-tc: var(--theme-color);
  --vp-tcl: var(--theme-color-light);
  --vp-ct: var(--color-transition);
  --vp-tt: var(--transform-transition);

  --warning-border-color: #e6a700;
  --warning-code-bg-color: rgb(230 167 0 / 15%);
  --warning-bg-color: #4d3800;

  --theme-color-dark: #389e70;
  --theme-color-light: #4abf8a;
  --theme-color-mask: rgba(62, 175, 124, .15);

  --badge-tip-color: #42b983;
  --badge-warning-color: #f4cd00;
  --badge-danger-color: #f55;
  --badge-info-color: #0295ff;
  --badge-note-color: #666;
}

[data-bs-theme="dark"] .navbar {
  --bs-navbar-color: #ea3377; 
}

[data-bs-theme="dark"] {
  --bs-body-bg: #140814; 
  --bg-color: #2a112a;  
  --bs-secondary-color: rgba(255, 255, 255, 0.5);
  --bg-color-blur: rgba(42, 17, 41, .9);
  --bs-nav-link-color: #ea3377; 
  --bs-link-color: #ea3377; 
  --bs-emphasis-color-rgb: #ea3377; 
  --text-color: #fff; 
  --link-color: #ea3377;
  --link-hover-color: #fff;
  --bg-color-navbar: #1b1020;
  --bs-border-color: #664e57;
  --bs-secondary-bg: #1b1020;
  --bs-link-color-rgb: 234, 51, 119;
}

.theme-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-page-wrapper {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: calc(var(--navbar-height) + 2rem);
  padding-bottom: 2rem;
  min-height: calc(100vh - var(--navbar-height) - 2rem);
}

footer {
  margin: .5rem 1rem;
  font-size: 14px;
}

.footer-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding-block: .75rem;
  padding-inline: calc(var(--sidebar-space) + 2rem) 2rem;
  border-top: 1px solid var(--border-color);
  color: var(--dark-grey);
  text-align: center;
  transition: border-top-color var(--color-transition),background var(--color-transition),padding var(--transform-transition);
}

/* Modal */
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 800px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: #e2e2e2;
  --bs-modal-bg: #1e1e1e;
  --bs-modal-border-color: rgb(255 255 255 / 18%);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: #49494c;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
}

.modal-backdrop {
  opacity:0.9 !important;
}

.modal-content {
  background: none;
  /*border: transparent;*/
  font-size: 0.9rem;
  font-weight: 600;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: none;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: none;
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

/* Buttons */
[data-bs-theme="light"] .btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #ea3377;
  --bs-btn-border-color: #b8e986;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ff4c4c;
  --bs-btn-hover-border-color: #ff4c4c;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ff4c4c;
  --bs-btn-active-border-color: #ff4c4c;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d7e5c9;
  --bs-btn-disabled-border-color: #d7e5c9;
}

[data-bs-theme="dark"] .btn-primary {
  --bs-btn-color: #ffaecc;
  --bs-btn-bg: #8d1642;
  --bs-btn-border-color: #ea3377;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ff4c4c;
  --bs-btn-hover-border-color: #ff4c4c;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ff4c4c;
  --bs-btn-active-border-color: #ff4c4c;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d7e5c9;
  --bs-btn-disabled-border-color: #d7e5c9;
}

[data-bs-theme="light"] .btn-outline-primary {
  --bs-btn-color: #000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ff4c4c;
  --bs-btn-hover-border-color: #ff4c4c;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ff4c4c;
  --bs-btn-active-border-color: #ff4c4c;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d7e5c9;
  --bs-btn-disabled-border-color: #d7e5c9;
  --bs-gradient: none;
}

[data-bs-theme="dark"] .btn-outline-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #fffefe;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ff4c4c;
  --bs-btn-hover-border-color: #ff4c4c;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ff4c4c;
  --bs-btn-active-border-color: #ff4c4c;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d7e5c9;
  --bs-btn-disabled-border-color: #d7e5c9;
  --bs-gradient: none;
}

.filter-buttons {
  display: flex;
  justify-content: flex-end;
}

.title{
  margin-bottom: 30px;
}

.card {
  box-shadow: 0 0 41px 4px #00000080;
}

.card-container {
  display: flex;
  flex-direction: column;
}

/* Form Group */
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea {
  border-width: 2px;
  font-size: 14px;
}

.form-group label {
  width: 150px;
  margin-right: 10px;
  text-align: right;
}

.form-select {
  border-width: 2px;
  font-size: 14px;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.checkbox-item {
  display: block;
  margin-bottom: 5px;
}

/* Responsive styles for smaller screens */
@media (max-width: 600px) { /* Adjust the max-width as needed for your design */
  .form-group {
    flex-direction: column; /* Stack label and input vertically */
    align-items: flex-start; /* Align items to the start of the flex container */
  }

  .form-group label {
    width: 100%;
    text-align: left;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    margin-left: 0px;
  }

  .checkbox-group {
    margin-left: 0px;
  }

}

.tooltip {
  position: absolute !important;
}