.nested-list {
  counter-reset: sub-item; /* Reset counter for nested list */
  list-style: none; /* Remove default list style */
  padding-left: 20px; /* Add padding for nesting */
}

.nested-list > li {
  counter-increment: sub-item; /* Increment counter for each list item */
}

.nested-list > li::before {
  content: "3." counter(sub-item) " "; /* Add parent number and sub-item */
  font-weight: bold;
}