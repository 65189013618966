.google-sign-in-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.google-sign-in-button:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.google-sign-in-button:active {
  transform: translateY(1px);
}

.google-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.text-dark {
  color: #333333;
}

.font-semibold {
  font-weight: 600;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}
