.login-container {
  max-width:400px;
  margin-bottom: 60px;
  margin: auto;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;
}

.input-label {
  margin-bottom: 0.3rem;
  font-weight: 500;
  color: var(--text-color);
}

.input-error {
  font-size: 0.85rem;
  color: #ff4c4c;
  margin-top: 0.3rem;
}

.input-control {
  width: 100%;
  padding: 0.6rem 0.75rem;
  border: 1px solid var(--border-color);
  background-color: var(--bs-secondary-bg);
  color: var(--text-color);
  font-size: 1rem;
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

.input-control:focus {
  border-color: var(--bs-border-color);
  outline: none;
  box-shadow: 0 0 0 2px var(--bs-btn-hover-border-color);
}

.input-control.error {
  border-color: #ff4c4c;
}

.turnstile-wrapper {
  transform: scale(0.9); /* Adjust scale as needed */
  transform-origin: top left;
}