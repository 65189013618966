/* nav logo */
.nav-logo {
  height: 2.2rem;
  vertical-align: middle;
  margin-inline-end: .8rem;
}

.navbar-brand {
  font-size: 1.5rem;
  color: #ffffff;
}

.navbar {
  --navbar-line-height: calc(var(--navbar-height) - var(--navbar-vertical-padding) * 2);
  position: fixed;
  inset: 0 0 auto;
  z-index: 175;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: var(--navbar-height);
  padding: var(--navbar-vertical-padding) var(--navbar-horizontal-padding);
  box-shadow: 0 2px 8px var(--card-shadow);
  line-height: var(--navbar-line-height);
  white-space: nowrap;
  transition: transform 0.3s ease-in-out, background var(--color-transition), box-shadow var(--color-transition);
  -webkit-backdrop-filter: saturate(150%) blur(12px);
  backdrop-filter: saturate(150%) blur(12px);
  flex-wrap: nowrap;
}

.navbar-nav {
  justify-content: flex-end !important;
  border:#fff;
}

.nav-link {
  padding: 5px 10px;
}

.nav-link:hover {
  color: var(--text-color);
}

/* Hamburger icon animation */
.navbar-toggler {
  border: none !important;
  background: none !important;
  outline: none !important;
  padding: 0;
  box-shadow: none !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: none !important;
  box-shadow: none !important;
}

.hamburger-icon {
  display: inline-block;
  cursor: pointer;
}

.hamburger-icon .bar1,
.hamburger-icon .bar2,
.hamburger-icon .bar3 {
  width: 25px;
  height: 2px;
  background-color: var(--text-color);
  margin: 6px 0;
  transition: 0.4s;
}

.navbar-toggler.open .bar1 {
  transform: rotate(-45deg) translate(-6px, 5px);
}

.navbar-toggler.open .bar2 {
  opacity: 0;
}

.navbar-toggler.open .bar3 {
  transform: rotate(45deg) translate(-6px, -5px);
}

/* Ensure the collapse opens from the right side */
/*
.navbar-collapse {
  max-height: 0 !important;
}

.navbar-collapse.show {
  max-height: 100vh !important;
  transform: translateX(0) !important;
  display: block !important;
}

.navbar-collapse.dropdown {
  animation: dropdown 0.4s forwards;
}

@keyframes dropdown {
  from {
    max-height: 0;
  }
  to {
    max-height: 100vh;
  }
}
*/

/*
@media (max-width: 992px) {
  .navbar-collapse {
      position: absolute;
      font-size: 16px;
      top: 60px;
      padding: 15px;
      width: 90%;
      max-width: 250px;
      transition: all 0.1s ease;
      display: block;
      background: #000;
  }
  .navbar-collapse.collapsing {
      height: auto !important;
      margin-left: 50%;
      left: 50%;
      transition: all 0.2s ease;
  }
  .navbar-collapse.show {
      right: 0;
  }
}
*/


@media (max-width: 992px) {
  .navbar-collapse {
      position: absolute;
      font-size: 16px;
      top: 60px;
      right: 0;
      padding: 15px;
      width: 90%;
      max-width: 250px;
      transition: transform 0.3s ease, opacity 0.3s ease;
      display: block;
      background: var(--bg-color-navbar);
      transform: translateX(100%);
      opacity: 0;
      height: 100vh; /* Full viewport height */
  }

  .navbar-collapse.show {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
      height: 100vh; /* Full viewport height */
  }

  .navbar-collapse.collapsing {
      transition: transform 0.3s ease, opacity 0.3s ease;
      transform: translateX(100%);
      opacity: 1;
      height: 100vh !important; /* Full viewport height */
  }
}



