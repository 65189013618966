
.terms-list {
  list-style: decimal;
}

.terms-list > li {
  margin-bottom: 2rem; /* Adds 1rem space only to the root list items */
}

.terms-list > li > ul > li {
  margin-bottom: 1rem; /* Adjust margin specifically for nested list items */
}

.terms-list > li > ol > li {
  margin-bottom: 1rem; /* Adjust margin specifically for nested list items */
}

.terms-list li::marker {
  font-weight: bold;
}