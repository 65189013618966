.dark-theme-input .form-control,
.dark-select__control {
  background-color: var(--bs-body-bg) !important;
  color: #e0e0e0 !important;
  border: 1px solid var(--bs-border-color) !important;
}

.dark-theme-input .form-control::placeholder,
.dark-select__placeholder {
  color: #fff !important;
}

.dark-theme-input .form-control:focus,
.dark-select__control--is-focused {
  border-color: #6200ea !important;
  box-shadow: 0 0 5px rgba(98, 0, 234, 0.8) !important;
}

.dark-select__menu {
  background-color: #1f1f1f !important;
  color: #e0e0e0 !important;
}

.dark-select__option {
  background-color: #1f1f1f !important;
  color: #e0e0e0 !important;
}

.dark-select__option--is-selected {
  background-color: #6200ea !important;
  color: #fff !important;
}

.dark-select__option--is-focused {
  background-color: #333 !important;
}

.dark-select__single-value {
  color: #fff !important;
}

.dark-select__input-container {
  color: #fff !important;
}


.hold-progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: #ffc107; /* yellow/orange */
  width: 100%;
  animation: holdBarFill 3s linear forwards;
  border-radius: 0 0 4px 4px;
}

@keyframes holdBarFill {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}