.product-name {
  display: -webkit-box;        /* Use WebKit's box model for multiline clamping */
  -webkit-line-clamp: 2;       /* Limit the description to 4 lines */
  -webkit-box-orient: vertical;/* Specifies vertical box orientation */
  overflow: hidden;            /* Hides any overflowed content */
  text-overflow: ellipsis;     /* Displays the ellipsis when overflowed */
  font-size: 20px;
  min-height: 3em;
}

.product-description {
  margin-top:10px;
  margin-bottom:10px;
  display: -webkit-box;        /* Use WebKit's box model for multiline clamping */
  -webkit-line-clamp: 2;       /* Limit the description to 4 lines */
  -webkit-box-orient: vertical;/* Specifies vertical box orientation */
  overflow: hidden;            /* Hides overflowing content */
  text-overflow: ellipsis;     /* Displays an ellipsis when content overflows */
  min-height: 3em;
}

.card-img-top{
	border-top-right-radius: 0.375rem;
	border-top-left-radius: 0.375rem;
}