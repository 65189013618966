.crt-detail-container {
  /*padding: 2rem 1rem;*/
}

.crt-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.crt-detail-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.crt-main {
  flex: 2;
  min-width: 300px;
}

.crt-sidebar {
  flex: 1;
  min-width: 250px;
}

.meta {
  font-size: 0.875rem;
  color: #888;
}

.crt-image img {
  max-width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.mod-labels {
  margin-bottom: 1rem;
}

.mod-label {
  display: inline-block;
  background-color: #198754; /* Bootstrap success */
  color: white;
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
}

.mod-notes {
  margin-bottom: 1rem;
  background-color: #2b132b;
  padding: 1rem;
  border-radius: 0.5rem;
}

.mod-link {
  display: inline-block;
  margin-bottom: 1.5rem;
  font-weight: 500;
  color: #0d6efd;
  text-decoration: none;
}

.mod-link:hover {
  text-decoration: underline;
}

.mod-board-label {
  background-color: #e0f7fa;
  color: #007b83;
  padding: 3px 8px;
  margin: 2px;
  border-radius: 4px;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 500;
}

.info-card {
  background-color: #2b132b;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  font-size: 0.9rem;
}

.info-item {
  margin-bottom: 0.5rem;
}


.crt-hero-image img {
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.crt-gallery {
  margin-top: 2rem;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  max-height: 950px; /* Optional: constrain overall height if needed */
  overflow-y: auto;  /* Enable scroll if too tall */
}

.gallery-thumb {
  width: 100%;
  height: 300px; /* Fixed height */
  object-fit: cover;
  border-radius: 4px;
  transition: transform 0.2s;
}

.gallery-thumb:hover {
  transform: scale(1.02);
}