.card-container {
  max-width: 600px;
  margin: 0px auto;
  border-radius: 6px;
  box-shadow: 0 0 41px 4px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  gap: 0rem;
}

.image-wrapper {
  position: relative;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  border-radius: 6px 6px 0 0;
}

.description-container {
  color: #fff;
  font-size: 18px;
  line-height: 25px;
  white-space: pre-wrap;
}

.description-editable {
  cursor: text;
  outline: 0;
  word-wrap: break-word;
  padding: 20px;
  border-radius: 0px 0px 5px 5px;
  min-height: 60px;
}

.description-editable:empty:before {
  content: attr(placeholder);
  color: #babec4;
  pointer-events: none;
}