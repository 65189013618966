/* Chat container layout */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 80vh; /* Full viewport height */
  max-width: 800px;
  margin: auto;
}

/* Center chat input when no messages exist */
.centered-chat {
  justify-content: center; /* Center input vertically */
}

/* Chat messages container (scrollable) */
.chat-box {
  flex-grow: 1; /* Expands when chat starts */
  overflow-y: auto;
  border-radius: 10px;
  /*border: 1px solid #ddd;*/
  display: flex;
  flex-direction: column;
}

/* Chat messages should be scrollable */
.chat-messages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: calc(100vh - 200px); /* Prevents overflow */
}

/* Placeholder for empty chat */
.empty-chat-placeholder {
  text-align: center;
  font-size: 18px;
  color: #777;
  margin: auto;
}

/* Individual chat messages */
.chat-message {
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 75%;
}

/* User messages */
.chat-message.user {
  align-self: flex-end;
  background: #10a37f;
  color: white;
}

/* Assistant messages */
.chat-message.assistant {
  align-self: flex-start;
  background: var(--bg-color-back);
}

/* Sticky chat input */
.chat-input-container {
  display: flex;
  align-items: center;
  padding: 12px;
  position: sticky;
  margin-top: 10px;
  border: 1px solid var(--bs-border-color-translucent);
  border-radius: 8px;
  bottom: 0;
  width: 100%;
}

/* Chat input field */
.chat-input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 12px 15px;
  border-radius: 8px;
}

/* Chat input focus */
.chat-input:focus {
  box-shadow: 0 0 0 .25rem rgba(255, 77, 196, 0.25);
  border: 0px solid #b3b3b3;
}

/* Send button (up arrow) */
.send-btn {
  background: #10a37f;
  color: white;
  border: none;
  padding: 10px 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in-out;
  margin-left: 10px;
}

.send-btn:hover {
  background: #0e8c6a;
}

/* Up arrow icon size */
.send-btn svg {
  font-size: 20px;
}
