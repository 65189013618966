.card-hover {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* If a CRT has an rgb_mod_link */
.card-hover.has-link {
  background-color: #790057; /* a pale green background */
}

.card-hover.has-link:hover {
  background-color: #50003f; /* slightly darker green on hover */
}

/* If a CRT does NOT have an rgb_mod_link */
.card-hover.no-link {
  background-color: #6a6a6a; /* light gray background */
}

.card-hover.no-link:hover {
  background-color: #484747; /* slightly darker gray on hover */
}

.custom-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

.custom-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* default: not moddable (gray) */
  transition: 0.4s;
  border-radius: 34px;
}

.custom-toggle .slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.custom-toggle input:checked + .slider {
  background-color: #28a745; /* green = moddable */
}

.custom-toggle input:checked + .slider:before {
  transform: translateX(24px);
}
