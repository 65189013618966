.vertical-timeline {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid var(--bs-border-color);
}

.timeline-bar {
  position: relative;
  margin-left: 30px;
  padding-left: 20px;
  border-left: 4px solid #ddd;
}

.timeline-step {
  position: relative;
  margin-bottom: 30px;
  padding-left: 20px;
}

.timeline-step:last-child {
  margin-bottom: 0;
}

.timeline-icon {
  position: absolute;
  top: 0;
  left: -34px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  background-color: #ddd;
  color: #fff;
  font-weight: bold;
}

.timeline-step.completed .timeline-icon {
  background-color: #ea3377; /* Green for completed */
}

.timeline-step.upcoming .timeline-icon {
  background-color: #6c757d; /* Gray for upcoming */
}

.timeline-content h5 {
  margin-bottom: 10px;
  font-weight: bold;
}

.timeline-content ul {
  list-style: none;
  padding: 0;
}

.timeline-content li {
  margin-bottom: 5px;
}

.timeline-content li input {
  margin-right: 10px;
}
