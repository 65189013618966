.add-purchase h1 {
  text-align: center;
}

.add-purchase form {
  display: flex;
  flex-direction: column;
}

.custom-field-group {
  display: block;
  align-items: center;
  width: 100%;
}