.timeline-container {
  display: flex;
  position: relative;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9) 66.67%, rgba(0, 0, 0, 0.3)), 
              url('../assets/dashboard_background.jpg'); /* Dark gradient on the left */  
  background-size: cover;
  background-position: center;
  z-index: 1;
  text-align: left;
}

.timeline-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 66.67%; /* Covers two-thirds of the screen */
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0.2; /* Highly faded */
  z-index: 1;
}

.vertical-timeline {
  position: relative;
  z-index: 2; /* Ensure this is above the background */
  width: 100%;
  padding: 20px;
}

.home-container {
  text-align: center;
}

.home-logo-gif {
  padding: 20px;
  width: 100%;
  max-width: 200px;
}

.home-logo {
  padding: 20px;
  width: 100%;
  max-width: 400px;
}

.feature-card {
  cursor: pointer; /* Makes the card appear clickable */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition effect */
}

.feature-card:hover {
  transform: scale(1.02); /* Slightly enlarges the card on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adds a shadow effect */
  background-color: rgba(37, 7, 32, 0.461);
}

.icon-container {
  flex-shrink: 0; /* Prevent the icon from shrinking */
  padding: 10px; /* Add some padding around the icon */
}

.feature-icon {
  width: 80px; /* Set a consistent width for icons */
  height: auto; /* Maintain aspect ratio */
}

.text-container {
  flex-grow: 1; /* Allow text to expand */
}

@media (max-width: 768px) {
  .feature-card {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center; /* Center-align content for smaller screens */
  }

  .feature-icon {
    width: 40px; /* Adjust icon size for smaller screens */
    margin-bottom: 10px; /* Add spacing below the icon */
  }
}




.masonry-grid {
  display: flex;
  margin-left: -16px; /* Adjust this to match your gap */
  width: auto;
}

.masonry-grid-column {
  padding-left: 16px; /* Adjust this to match your gap */
  background-clip: padding-box;
}

.masonry-grid-column > div {
  margin-bottom: 16px; /* Space between items */
}



.search-bar-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.autocomplete-list {
  list-style: none;
  padding: 0;
  margin: 5px 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.autocomplete-list li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.autocomplete-list li:hover {
  background: #f0f0f0;
}

.chat-response-card {
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  background: #f8f9fa;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}



.chat-input-container {
  display: flex;
  gap: 10px;
  margin: 20px 0;
}

.chat-input {
  border: 0px;
  flex-grow: 0;
  padding: 10px;
}

.send-btn {
  padding: 10px 16px;
}

.chat-messages {
  margin-top: 10px;
}

.chat-message {
  margin-bottom: 10px;
}

.chat-message.user {
  text-align: right;
}

.chat-message.assistant {
  text-align: left;
}

.cursor {
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}